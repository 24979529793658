<template>
  <div v-if="validations">
    <span
      v-for="(validation, index) in validations"
      :key="index"
      v-if="validation.condition"
      class="block cl-primary-orange h6 pt5 absolute error"
      data-testid="errorMessage"
    >
      {{ validation.text }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    validations: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  left: 0;
  bottom: -15px;
  right: -200px;
  // width: 300px;
  text-align: left;
  padding-left: 10px;
}
</style>
