<template>
  <component
    :is="compontentType"
    :type="!link ? type : false"
    :to="redirectionLink"
    aria-label="button full"
    class="no-outline button-full uppercase block brdr-none px10 py10 ripple weight-100 h4 bg-cl-orange cl-white fs-medium brdr-radius-20"
    :class="{ 'no-underline pointer align-center border-box': link, 'disabled': disabled, '': disabled }"
    data-testid="subscribeSubmit"
    :style="styles"
    :disabled="disabled"
  >
    <slot>
      Button
    </slot>
  </component>
</template>

<script>
import focusClean from 'theme/components/theme/directives/focusClean'
export default {
  name: 'ButtonFull',
  directives: { focusClean },
  props: {
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    link: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    styles: {
      type: String,
      default: null,
      required: false
    }
  },
  computed: {
    compontentType () {
      return this.link ? 'router-link' : 'button'
    },
    redirectionLink () {
      return this.link ? this.localizedRoute(this.link) : null
    }
  }
}
</script>

<style lang="scss" scoped>
  .disabled {
    background-color: #d9d9d9
    // background: none;
  }
</style>

<style lang="scss">
  .button-full {
    min-width: 200px;
  }
  .brdr-radius-20 {
    border-radius: 20px;
  }
</style>
