import { module } from './store'
import { afterRegistration } from './hooks/afterRegistration'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { isServer } from '@vue-storefront/core/helpers'
import config from 'config'

const KEY = 'googleRecaptcha';

export const GoogleRecaptcha: StorefrontModule = function ({ store }) {
  store.registerModule(KEY, module);
  afterRegistration(config, isServer);
}
