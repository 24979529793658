var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'w-100': _vm.isCheckoutPage }},[_c('header',{staticClass:"modal-header py15 px65 h2 serif weight-700 center-sm hidden-xs relative"},[_c('img',{staticClass:"modal-close filter-color",attrs:{"src":"/assets/svg/Krzyzyk.svg","width":"24px","height":"24px","alt":"krzyzyk"},on:{"click":_vm.close}}),_vm._v("\n    "+_vm._s(_vm.$t('Zarejestruj się'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"modal-content pb60 px65 cl-secondary",class:[{ 'pt60' : !_vm.isMobile }, { 'full-screen-login' : _vm.isMobile && !_vm.isCheckoutPage }]},[_c('img',{staticClass:"modal-close visible-xs",attrs:{"src":"/assets/svg/Krzyzyk.svg","width":"24px","height":"24px","alt":"krzyzyk"},on:{"click":_vm.close}}),_vm._v(" "),(_vm.isMobile && !_vm.isCheckoutPage)?_c('div',{staticClass:"flex center-xs pb20 pt140"},[_c('i',{staticClass:"icon-ag_grzejniki-logo icon-size cl-white"})]):_vm._e(),_vm._v(" "),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('base-input',{staticClass:"mb35",class:{ 'light-version' : _vm.isMobile && !_vm.isCheckoutPage },attrs:{"type":"email","name":"email","autocomplete":"email","focus":"","placeholder":_vm.$t('E-mail address *'),"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: _vm.$t('Please provide valid e-mail address.')
          }
        ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('div',{staticClass:"row mb35"},[_c('base-input',{staticClass:"col-xs-6",class:{ 'light-version' : _vm.isMobile && !_vm.isCheckoutPage },attrs:{"type":"text","name":"fist-name","autocomplete":"given-name","placeholder":_vm.$t('First name *'),"validations":[
            {
              condition: !_vm.$v.firstName.required && _vm.$v.firstName.$error,
              text: _vm.$t('Field is required.')
            },
            {
              condition: !_vm.$v.firstName.minLength,
              text: _vm.$t('Name must have at least 2 letters.')
            },
            {
              condition: !_vm.$v.firstName.stringRegex,
              text: 'Imię może zawierać wyłącznie litery.'
            }
          ]},on:{"blur":function($event){return _vm.$v.firstName.$touch()}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-6",class:{ 'light-version' : _vm.isMobile && !_vm.isCheckoutPage, 'pb10' : !_vm.$v.lastName.stringRegex },attrs:{"type":"text","name":"last-name","autocomplete":"last-name","placeholder":_vm.$t('Last name *'),"validations":[{
            condition: !_vm.$v.lastName.required && _vm.$v.lastName.$error,
            text: _vm.$t('Field is required.')
          }, {
            condition: !_vm.$v.lastName.stringRegex,
            text: 'Nazwisko może zawierać wyłącznie litery.'
          }]},on:{"blur":function($event){return _vm.$v.lastName.$touch()}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_vm._v(" "),_c('base-input',{ref:"password",staticClass:"mb35 password",class:{
          'light-version' : _vm.isMobile && !_vm.isCheckoutPage
        },attrs:{"type":"password","name":"password","autocomplete":"new-password","placeholder":_vm.$t('Password *'),"validations":[
          {
            condition: !_vm.$v.password.required && _vm.$v.password.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.password.minLength && _vm.$v.password.$error,
            text: _vm.$t('Password must have at least 6 letters.')
          }
        ]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('base-input',{staticClass:"mb35",class:{ 'light-version' : _vm.isMobile && !_vm.isCheckoutPage },attrs:{"type":"password","name":"password-confirm","autocomplete":"new-password","placeholder":_vm.$t('Repeat password *'),"validations":[
          {
            condition: !_vm.$v.rPassword.required && _vm.$v.rPassword.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.rPassword.sameAsPassword && _vm.$v.rPassword.required,
            text: _vm.$t('Passwords must be identical.')
          }
        ]},on:{"blur":function($event){return _vm.$v.rPassword.$touch()}},model:{value:(_vm.rPassword),callback:function ($$v) {_vm.rPassword=$$v},expression:"rPassword"}}),_vm._v(" "),_c('base-checkbox',{staticClass:"mb25",class:{ 'cl-white' : _vm.isMobile },attrs:{"id":"terms","validations":[{
          condition: !_vm.$v.conditions.required && _vm.$v.conditions.$error,
          text: _vm.$t('You must accept the terms and conditions.')
        }]},on:{"blur":function($event){return _vm.$v.conditions.$reset()},"change":function($event){return _vm.$v.conditions.$touch()}},model:{value:(_vm.conditions),callback:function ($$v) {_vm.conditions=$$v},expression:"conditions"}},[_vm._v("\n        "+_vm._s(_vm.$t('I accept '))+"\n        "),_c('router-link',{staticClass:"link pointer link-hover",attrs:{"target":"_blank","to":_vm.localizedRoute('/i/regulamin-serwisu')}},[_vm._v("\n          "+_vm._s(_vm.$t('Terms and conditions'))+"\n        ")]),_vm._v("*\n      ")],1),_vm._v(" "),_c('button-full',{staticClass:"mb20 w-100",attrs:{"type":"submit"}},[_vm._v("\n        "+_vm._s(_vm.$t('Zarejestruj się'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"center-xs weight-mobile",class:{ 'cl-white h5' : _vm.isMobile }},[_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('or'))+"\n          "),_c('a',{staticClass:"link-hover",class:{ 'cl-white links h5' : _vm.isMobile },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem($event)}}},[_vm._v(_vm._s(_vm.$t('login to your account')))])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }