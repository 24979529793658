<template>
  <div :class="{ 'w-100': isCheckoutPage }">
    <header
      class="modal-header py15 px65 h2 serif weight-700 center-sm hidden-xs relative"
    >
      <img src="/assets/svg/Krzyzyk.svg" width="24px" height="24px" alt="krzyzyk" class="modal-close filter-color" @click="close">
      {{ $t('Zarejestruj się') }}
    </header>

    <div
      class="modal-content pb60 px65 cl-secondary"
      :class="[{ 'pt60' : !isMobile }, { 'full-screen-login' : isMobile && !isCheckoutPage }]"
    >
      <img src="/assets/svg/Krzyzyk.svg" width="24px" height="24px" alt="krzyzyk" class="modal-close visible-xs" @click="close">
      <div class="flex center-xs pb20 pt140" v-if="isMobile && !isCheckoutPage">
        <i class="icon-ag_grzejniki-logo icon-size cl-white" />
      </div>
      <form @submit.prevent="register" novalidate>
        <base-input
          class="mb35"
          :class="{ 'light-version' : isMobile && !isCheckoutPage }"
          type="email"
          name="email"
          autocomplete="email"
          v-model="email"
          @blur="$v.email.$touch()"
          focus
          :placeholder="$t('E-mail address *')"
          :validations="[
            {
              condition: !$v.email.required && $v.email.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.email.email && $v.email.$error,
              text: $t('Please provide valid e-mail address.')
            }
          ]"
        />
        <div class="row mb35">
          <base-input
            class="col-xs-6"
            :class="{ 'light-version' : isMobile && !isCheckoutPage }"
            type="text"
            name="fist-name"
            autocomplete="given-name"
            v-model="firstName"
            @blur="$v.firstName.$touch()"
            :placeholder="$t('First name *')"
            :validations="[
              {
                condition: !$v.firstName.required && $v.firstName.$error,
                text: $t('Field is required.')
              },
              {
                condition: !$v.firstName.minLength,
                text: $t('Name must have at least 2 letters.')
              },
              {
                condition: !$v.firstName.stringRegex,
                text: 'Imię może zawierać wyłącznie litery.'
              }
            ]"
          />
          <base-input
            class="col-xs-6"
            :class="{ 'light-version' : isMobile && !isCheckoutPage, 'pb10' : !$v.lastName.stringRegex }"
            type="text"
            name="last-name"
            autocomplete="last-name"
            v-model="lastName"
            @blur="$v.lastName.$touch()"
            :placeholder="$t('Last name *')"
            :validations="[{
              condition: !$v.lastName.required && $v.lastName.$error,
              text: $t('Field is required.')
            }, {
              condition: !$v.lastName.stringRegex,
              text: 'Nazwisko może zawierać wyłącznie litery.'
            }]"
          />
        </div>
        <base-input
          class="mb35 password"
          :class="{
            'light-version' : isMobile && !isCheckoutPage
          }"
          type="password"
          name="password"
          ref="password"
          autocomplete="new-password"
          v-model="password"
          @blur="$v.password.$touch()"
          :placeholder="$t('Password *')"
          :validations="[
            {
              condition: !$v.password.required && $v.password.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.password.minLength && $v.password.$error,
              text: $t('Password must have at least 6 letters.')
            }
          ]"
        />
        <base-input
          class="mb35"
          :class="{ 'light-version' : isMobile && !isCheckoutPage }"
          type="password"
          name="password-confirm"
          autocomplete="new-password"
          v-model="rPassword"
          @blur="$v.rPassword.$touch()"
          :placeholder="$t('Repeat password *')"
          :validations="[
            {
              condition: !$v.rPassword.required && $v.rPassword.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.rPassword.sameAsPassword && $v.rPassword.required,
              text: $t('Passwords must be identical.')
            }
          ]"
        />
        <base-checkbox
          class="mb25"
          :class="{ 'cl-white' : isMobile }"
          id="terms"
          v-model="conditions"
          @blur="$v.conditions.$reset()"
          @change="$v.conditions.$touch()"
          :validations="[{
            condition: !$v.conditions.required && $v.conditions.$error,
            text: $t('You must accept the terms and conditions.')
          }]"
        >
          {{ $t('I accept ') }}
          <router-link
            class="link pointer link-hover"
            target="_blank"
            :to="localizedRoute('/i/regulamin-serwisu')"
          >
            {{ $t('Terms and conditions') }}
          </router-link>*
        </base-checkbox>
        <button-full class="mb20 w-100" type="submit">
          {{ $t('Zarejestruj się') }}
        </button-full>
        <div class="center-xs weight-mobile" :class="{ 'cl-white h5' : isMobile }">
          <span>
            {{ $t('or') }}
            <a
              href="#"
              class="link-hover"
              :class="{ 'cl-white links h5' : isMobile }"
              @click.prevent="switchElem"
            >{{ $t('login to your account') }}</a>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { MobileDetected } from 'theme/components/core/mobileDetected.ts'
import Register from '@vue-storefront/core/compatibility/components/blocks/Auth/Register';
import ButtonFull from 'theme/components/theme/ButtonFull.vue';
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox.vue';
import BaseInput from 'theme/components/core/blocks/Form/BaseInput.vue';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import CurrentPage from 'theme/mixins/currentPage';
import { registerModule } from '@vue-storefront/core/lib/modules'
import { GoogleRecaptcha } from 'src/modules/vsf-recaptcha'
import rootStore from '@vue-storefront/core/store'
import { Logger } from '@vue-storefront/core/lib/logger'

const stringRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return (/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/).test(value)
}

export default {
  data () {
    return {
      reCaptchaValid: ''
    };
  },
  validations: {
    email: {
      required,
      email
    },
    firstName: {
      minLength: minLength(2),
      required,
      stringRegex
    },
    lastName: {
      required,
      stringRegex
    },
    password: {
      minLength: minLength(6),
      required
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    },
    conditions: {
      required
    }
  },
  mixins: [Register, CurrentPage, MobileDetected],
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput
  },
  beforeCreate () {
    registerModule(GoogleRecaptcha)
  },
  mounted () {
    this.showRecaptchaBadge()
  },
  beforeDestroy () {
    this.hideRecaptchaBadge()
  },
  methods: {
    showRecaptchaBadge () {
      if (document.getElementsByClassName('grecaptcha-badge').length) document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'visible'
    },
    hideRecaptchaBadge () {
      document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'hidden'
    },
    callRegister () {
      this.$bus.$emit('notification-progress-start', this.$t('Registering the account ...'))
      this.$store.dispatch('user/register', { email: this.email, password: this.password, firstname: this.firstName, lastname: this.lastName, gToken: this.$store.state.googleRecaptcha.google_generated_token }).then(async (result) => {
        Logger.debug(result, 'user')()
        this.$bus.$emit('notification-progress-stop')
        if (result.code !== 200) {
          this.onFailure(result)
          // If error includes a word 'password', focus on a corresponding field
          if (result.result.includes('password')) {
            this.$refs['password'].setFocus('password')
            this.password = ''
            this.rPassword = ''
          }
        } else {
          await this.$store.dispatch('user/login', { username: this.email, password: this.password })
          this.onSuccess()
          this.close()
        }
      }).catch(err => {
        this.onFailure({ result: 'Unexpected authorization error. Check your Network conection.' })
        this.$bus.$emit('notification-progress-stop')
        Logger.error(err, 'user')()
      })
    },
    async register () {
      try {
        if (this.$v.$invalid) {
          this.$v.$touch()
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Please fix the validation errors'),
            action1: { label: this.$t('OK') }
          })
          return
        }
        await this.$store.dispatch('googleRecaptcha/isVerifiedCaptcha')
        if (this.$store.state.googleRecaptcha.google_generated_token) {
          this.callRegister()
        }
      } catch (error) {
        rootStore.dispatch('notification/spawnNotification', {
          type: 'error',
          message: error,
          action1: { label: this.$t('OK') }
        })
      }
    },
    onSuccess () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: this.$t('You are logged in!'),
        action1: { label: this.$t('OK') }
      });
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      });
    },
    submit (response) {
      if (response) {
        this.reCaptchaValid = response;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 50px;
}
.link-hover {
  @media (min-width: 767px) {
    color: #828282;
    &:hover {
      text-decoration: underline;
    }
  }
}
.filter-color {
  filter: invert(1);
}
.weight-mobile {
  @media (max-width: 767px) {
    font-weight: 100;
  }
}
.modal-close {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 10px;
  font-size: 24px;
  padding: 15px 25px;
  @media (max-width: 767px) {
    top: 0;
  }
  @media (max-width: 600px) {
    padding: 15px 15px;
  }
}
.modal-content {
  @media (max-width: 400px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.full-screen-login {
  height: 100vh;
  overflow-y: auto;
  max-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px;
  background: url("/assets/login-bg.png") 0 0 no-repeat;
  background-position: center;
  background-size: cover;
}
.links {
  text-decoration: underline;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background:transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
</style>

<style>
/* recaptcha badge */
.grecaptcha-badge {
  z-index: 10;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: -2px !important;
  bottom: 20px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
</style>
