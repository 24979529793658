import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import config from 'config'
import { isServer } from '@vue-storefront/core/helpers'

// actions
const actions: ActionTree<any, RootState> = {
  /**
   * Check is verified google captcha
   */
  isVerifiedCaptcha ({ commit, dispatch, state }) {
    if (!isServer && typeof window.grecaptcha !== 'undefined') {
      return new Promise((resolve, reject) => {
        try {
          window.grecaptcha.ready(async () => {
            // do request for recaptcha token
            const gToken = await window.grecaptcha.execute(config.googleRecaptcha.tokens.site_key)
            commit('SET_CAPTCHA_TOKEN', gToken)
            resolve(gToken)
          })
        } catch (e) {
          reject('Recaptcha was not verified!')
        }
      })
    }
    return true
  }
}

export default actions
