<template>
  <div class="relative base-input">
    <div class="relative">
      <input
        class="py10 w-100 border-box brdr-none brdr-cl-primary h4 sans-serif"
        :class="{pr30: type === 'password', empty: value === ''}"
        :type="type === 'password' ? passType : type"
        :name="name"
        :autocomplete="autocomplete"
        :value="value"
        :autofocus="autofocus"
        :ref="name"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        @keyup.enter="$emit('keyup.enter', $event.target.value)"
        @keyup="$emit('keyup', $event)"
        :disabled="disabled"
      >
      <label class="base-label weight-mobile">{{ placeholder }}</label>
      <label
        class="validate-circle"
        :class="[(isError() || value === '') ? 'not-valid' : 'cl-white valid' ]"
      >
        <i class="h5 check center-flex-text">&#10003;</i>
      </label>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue';

export default {
  name: 'BaseInput',
  components: {
    ValidationMessages
  },
  data () {
    return {
      passType: 'password',
      iconActive: false,
      icon: 'visibility_off'
    };
  },
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      defalult: false
    }
  },
  methods: {
    isError () {
      let hasError = false;
      this.validations.forEach(val => {
        if (val.condition) {
          hasError = true;
        }
      });
      return hasError;
    },
    // togglePassType () {
    //   if (this.passType === 'password') {
    //     this.passType = 'text'
    //     this.icon = 'visibility'
    //   } else {
    //     this.passType = 'password'
    //     this.icon = 'visibility_off'
    //   }
    // },
    // setFocus sets focus on a field which has a value of 'ref' tag equal to fieldName
    setFocus (fieldName) {
      if (this.name === fieldName) {
        this.$refs[this.name].focus();
      }
    }
  },
  created () {
    // if (this.type === 'password') {
    //   this.iconActive = true
    // }
  },
  mounted () {
    if (this.focus) {
      this.$refs[this.name].focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-tertiary: color(tertiary);
$color-black: color(black);
$color-puerto-rico: color(puerto-rico);
$color-hover: color(tertiary, $colors-background);

.check {
  width: 16px;
  height: 16px;
}
.weight-mobile {
  @media (max-width: 767px) {
    font-weight: 100;
  }
}
input {
  background: inherit;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  padding: 10px;
  font-size: 14px;
  line-height: 38px;
  height: 38px;
  &:hover,
  &:focus {
    outline: none;
    border-color: #999;
  }

  &:disabled,
  &:disabled + label {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
.base-label {
  color: #999;
  position: absolute;
  pointer-events: none;
  user-select: none;
  left: 10px;
  top: 11px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  font-size: 14px;
}
input:focus ~ .base-label,
input:not(.empty) ~ .base-label {
  top: -20px;
  font-size:14px;
  // color:$color-black;
  // display: none;
}
.validate-circle {
  position: absolute;
  pointer-events: none;
  user-select: none;
  right: 10px;
  top: 8px;
  left: auto;
  display: flex;
  padding: 2px;
  border: 1px solid;
  border-radius: 30px;
}
.not-valid {
  border-color: #d3d3d3;
  color: #d3d3d3;
}
.valid {
  border-color: #ff8100 !important;
  background: #ff8100;
}
.icon {
  right: 6px;
  top: 10px;
  &:hover,
  &:focus {
    color: $color-hover;
  }
}
.light-version {
  input {
    color: white;
    border-color: white;
  }
  .base-label {
    color: white;
  }
  .validate-circle {
    color: white;
    border-color: white;
  }
}
</style>
