import { isServer } from '@vue-storefront/core/helpers'

export default {
  data () {
    return {
      productPageRoutes: [
        'product',
        'virtual-product',
        'bundle-product',
        'simple-product',
        'downloadable-product',
        'grouped-product',
        'configurable-product'
      ],
      isProductPage: false,
      isCheckoutPage: false,
      isStaticPage: false,
      isHomePage: false,
      isComparePage: false,
      isBrandPage: false,
      isAccountPage: false
    }
  },
  watch: {
    '$route.name': function () {
      this.setCurrentPage()
    }
  },
  computed: {
    canGoBack () {
      return !this.isHistoryEmpty() && this.isProductPage
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      this.isHomePage = this.$route.name === 'home'
      this.isComparePage = this.$route.name === 'compare'
      this.isProductPage = !!this.$route.params.parentSku
      this.isCategoryPage = this.$route.name.includes('urldispatcher') && !this.isProductPage
      this.isCheckoutPage = this.$route.name === 'checkout' || this.$route.name === 'en-checkout'
      this.isStaticPage = this.$route.name === 'cms-page' || this.$route.name === 'en-cms-page'
      this.isAccountPage = this.$route.name === 'my-account' || this.$route.name === 'en-my-account'
      this.isBrandPage = this.$route.params.slug === 'brands' || this.$route.params.slug === 'marki'
    },
    currentPage () {
      if (this.isBrandPage) {
        return 'brand'
      } else if (this.isHomePage) {
        return 'homepage'
      } else if (this.isProductPage) {
        return 'product'
      } else if (this.isCheckoutPage) {
        return 'checkout'
      } else if (this.isStaticPage) {
        return 'static-page'
      } else if (this.isAccountPage) {
        return 'my-account'
      } else if (this.isCategoryPage) {
        return 'category'
      } else {
        return 'unknown'
      }
    },
    isIos () {
      if (!isServer) {
        const userAgent = window.navigator.userAgent.toLowerCase()
        return /iphone|ipad|ipod/.test(userAgent)
      }
      return false
    },
    // Check if history is empty
    isHistoryEmpty () {
      if (this.isIos()) {
        return false
      } else {
        if (!isServer) {
          return window.history.length <= 1
        }

        return false
      }
    }
  }
}
